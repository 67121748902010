import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';

import Logo from '../components/logo';
import Layout from '../components/layout';
import Hero from '../components/hero';
import Hours from '../components/hours';
import color from '../styles/color';

const IndexPage = ({ data: { andrea, doreen, henry } }) => (
  <Layout footer={true}>
    <Hero color="primary" size="fullheight-with-navbar">
      <div style={{ marginBottom: 60 }}>
        <div className="columns is-variable is-8">
          <div className="column is-one-third-desktop ">
            <div style={{ marginBottom: 30 }}>
              <Logo color={color.white} />
            </div>
            <h1 className="is-hidden title is-1 ">Boroyan Eye Associates</h1>
            <p className="title has-text-weight-light is-4 has-text-light">
              For the Lifetime Care of Your Eyes
            </p>
          </div>
          <div className="column">

            <div className="content ">
              <p>
                Welcome to Boroyan Eye Associates. Our office is dedicated to providing you and your
                family with innovative and dependable solutions for your visual needs. From
                pediatric care to your first contact lens fitting, from stylish eyewear to
                preventive glaucoma testing, our objective is to provide for the lifetime care of
                your eyes.
              </p>
              <p>Hope to see you soon!</p>
              <p>
                Doctor Henry Boroyan
                <br />
                Doctor Andrea Vosbikian
                <br />
                Optical Technician Doreen Stanizzi
              </p>
            </div>
          </div>
        </div>
      </div>

    </Hero>
  </Layout>
);

export default IndexPage;

export const query = graphql`
  query {
    andrea: file(relativePath: { eq: "andrea-portrait.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 768) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    henry: file(relativePath: { eq: "henry-portrait.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 768) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    doreen: file(relativePath: { eq: "doreen-portrait.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 768) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
